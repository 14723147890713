import { gql } from "@apollo/client";
import {
  AssignmentRelationRole,
  Cohort,
  WeekCalendarHeader_HolidayFragment,
} from "@generated/graphql";
import { LocalizedWeekday } from "@utils/dateTime";
import { Routes } from "@utils/routes";
import { ICalendarGeneratorPopover } from "components/iCalendar/ICalendarGeneratorPopover";
import {
  ICalendarInputMT,
  ICalendarInputTT,
} from "components/iCalendar/ICalendarInputEditors";
import { buildCalendarNameFuncForUserRelations } from "components/iCalendar/iCalendarUtils";
import { Button, Icon } from "components/shared";
import { CalendarEventCohortInstanceInfo } from "components/weekCalendar";
import { useAuth } from "contexts/AuthProvider";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { CancelWeekEventsModal } from "./components/CancelWeekEventsModal";
import { MonthDisplay } from "./components/MonthDisplay";
import { ScheduleNavigationCalendars } from "./components/ScheduleNavigationCalendar";

WeekCalendarHeader.fragments = {
  holiday: gql`
    fragment WeekCalendarHeader_Holiday on Holiday {
      id
      ...ScheduleNavigationCalendars_Holiday
    }
    ${ScheduleNavigationCalendars.fragments.holiday}
  `,
};

type Props = {
  viewDate: Date;
  startBound?: Date;
  endBound?: Date;
  eventInstanceInfos: CalendarEventCohortInstanceInfo[];
  externalControls?: React.ReactNode;
  holidays: WeekCalendarHeader_HolidayFragment[];
  localizedWeekdays: LocalizedWeekday[];
  cohortIds: Cohort["id"][];
  setViewDate: (date: Date) => void;
};

export function WeekCalendarHeader({
  viewDate,
  startBound,
  endBound,
  eventInstanceInfos,
  externalControls,
  holidays,
  localizedWeekdays,
  cohortIds,
  setViewDate,
}: Props) {
  const router = useRouter();
  const { isAdmin, isMentor, user } = useAuth();
  const [showCancelWeekModal, setShowCancelWeekModal] = useState(false);

  const noEvents = eventInstanceInfos.length === 0;
  const eventsHaveStarted =
    !noEvents && new Date() > eventInstanceInfos[0].startDateTime;

  const exportCalendarButton =
    router.pathname === Routes.mySchedule.path() && user ? (
      <ICalendarGeneratorPopover
        buttonLabel="Export your Calendar"
        calendarNameFunc={buildCalendarNameFuncForUserRelations(
          user.id,
          user.fullName
        )}
        initialBuildICalendarInput={{
          users: [
            {
              userId: user.id,
              relations: isMentor
                ? [AssignmentRelationRole.EngagementMentor]
                : [
                    AssignmentRelationRole.CohortPrimaryTeacher,
                    AssignmentRelationRole.CohortSubstituteTeacher,
                  ],
            },
          ],
        }}
        openOrientation="BOTTOM-START"
        buttonClassName="text-sm text-gray-700 font-medium bg-white hover:bg-gray-50"
        inputEditor={
          isMentor ? ICalendarInputMT(user.id) : ICalendarInputTT(user.id)
        }
      />
    ) : null;

  return (
    <header className="relative z-50 flex flex-none items-center justify-between gap-x-2 h-auto pb-4 lg:pl-2">
      <div className="flex flex-col gap-2 w-fit">
        <MonthDisplay
          className="text-lg lg:text-[35px] leading-8 lg:font-semibold lg:text-gray-900 col-span-2"
          firstDay={localizedWeekdays[0]}
          lastDay={localizedWeekdays[6]}
        />

        {isAdmin && (
          <Button
            height="sm"
            className="flex gap-x-2 w-fit col-span-2"
            disabled={noEvents || eventsHaveStarted}
            onClick={() => setShowCancelWeekModal(true)}
          >
            <Icon icon="cancelCalendar" color="text-white" size={4} />
            Cancel Week Sessions
          </Button>
        )}

        <div className="flex flex-col gap-y-1 sm:col-span-1 col-span-2">
          {externalControls}
        </div>

        {exportCalendarButton && (
          <div className="lg:flex sm:col-span-1 col-span-2">
            {exportCalendarButton}
          </div>
        )}
      </div>

      {/* Desktop Header Menu */}
      <div className="flex flex-1 h-full">
        <ScheduleNavigationCalendars
          events={eventInstanceInfos}
          viewDate={viewDate}
          holidays={holidays}
          startBound={startBound}
          endBound={endBound}
          cohortIds={cohortIds}
          setViewDate={setViewDate}
        />
      </div>

      <CancelWeekEventsModal
        show={showCancelWeekModal}
        events={eventInstanceInfos}
        firstDay={localizedWeekdays[0]}
        lastDay={localizedWeekdays[6]}
        cancelModal={() => setShowCancelWeekModal(false)}
      />
    </header>
  );
}
