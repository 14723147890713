import { LocalizedWeekday } from "@utils/dateTime";
import { Modal } from "components/shared";
import { CalendarEventCohortInstanceInfo } from "components/weekCalendar";
import { format } from "date-fns";
import { groupBy } from "lodash";
import pluralize from "pluralize";
import { useCancelEvents } from "../../CalendarEvents";

type Props = {
  show: boolean;
  cancelModal: () => void;
  events: CalendarEventCohortInstanceInfo[];
  firstDay: LocalizedWeekday;
  lastDay: LocalizedWeekday;
};

export function CancelWeekEventsModal({
  show,
  cancelModal,
  events,
  firstDay,
  lastDay,
}: Props) {
  const cancelEvents = useCancelEvents({
    cancellableEvents: events,
    onSuccess: cancelModal,
  });

  const eventsByWeekday = groupBy(events, (event) => {
    return event.startDateTime.getDay();
  });

  const listItems = Object.entries(eventsByWeekday).map(([weekday, events]) => (
    <li key={weekday}>
      {`${format(events[0].startDateTime, "EEEE, MMM d")}: `}
      <span className="font-bold">{`${events.length} ${pluralize(
        "event",
        events.length
      )}`}</span>
    </li>
  ));

  const modalBody =
    events.length === 0 ? (
      <div>No events to cancel</div>
    ) : (
      <div className="flex flex-col gap-5 px-3 mb-10">
        <div className="font-semibold">
          Please confirm the cancellation of the following events:
        </div>
        <ul className="list-disc">{listItems}</ul>
      </div>
    );

  return (
    <Modal
      show={show}
      onClose={cancelModal}
      icon={<Modal.Icon icon="cancelCalendar" type="danger" />}
      title={`Cancel All Week Events`}
      subtitle={`Week of ${firstDay.isoDate} to ${lastDay.isoDate}`}
      width="medium"
    >
      <div className="pt-3">
        {modalBody}

        <Modal.Buttons>
          <Modal.Button
            type="delete"
            onClick={cancelEvents}
            disabled={!events.length}
          >
            Cancel Sessions
          </Modal.Button>
          <Modal.Button type="cancel" onClick={cancelModal}>
            Exit
          </Modal.Button>
        </Modal.Buttons>
      </div>
    </Modal>
  );
}
