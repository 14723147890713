import { CohortEventInstanceStatus } from "@generated/graphql";
import { IANAtzName, LocalizedWeekday, WeekdayNumber } from "@utils/dateTime";
import {
  assertCalendarEventCohortInstance,
  CalendarEventInfo,
  DEFAULT_EVENT_COLOR,
  EVENT_COLORS,
} from "components/weekCalendar";
import uniq from "lodash/uniq";
import { useMemo } from "react";
import { CalendarEvent } from "./components/CalendarEvent/CalendarEvent";
import { OverlapCalendarEvents } from "./components/OverlapCalendarEvents/OverlapCalendarEvents";
import { mergeOverlappingEvents } from "./utils";

type Props = {
  localizedWeekdays: LocalizedWeekday[];
  focusedWeekday: WeekdayNumber;
  eventInfos: CalendarEventInfo[];
  locale: string;
  viewingTimeZone: IANAtzName;
  mode24Hour: boolean;
  refetch?: () => void;
};

export const CalendarEvents = ({
  localizedWeekdays,
  focusedWeekday,
  eventInfos,
  locale,
  viewingTimeZone,
  mode24Hour,
  refetch,
}: Props) => {
  const uniqueEventKeys = uniq(eventInfos.map((e) => e.groupKey));

  const overlappingEventsArray = useMemo(
    () => mergeOverlappingEvents(eventInfos),
    [eventInfos]
  );

  return (
    <>
      {overlappingEventsArray.map((eventGroup, i) => {
        const eventGroupWithColor = eventGroup.map((event) => {
          const groupNumber = uniqueEventKeys.findIndex(
            (key) => key === event.groupKey
          );
          const eventColor =
            assertCalendarEventCohortInstance(event) &&
            event.status === CohortEventInstanceStatus.Cancelled
              ? DEFAULT_EVENT_COLOR
              : EVENT_COLORS[groupNumber % EVENT_COLORS.length];

          return {
            ...event,
            eventColor,
          };
        });

        return eventGroupWithColor.length > 1 ? (
          <OverlapCalendarEvents
            key={`${eventGroupWithColor[0].groupKey}-${i}-${eventGroupWithColor.length}`}
            eventInfos={eventGroupWithColor}
            localizedWeekdays={localizedWeekdays}
            focusedWeekday={focusedWeekday}
            locale={locale}
            mode24Hour={mode24Hour}
            viewingTimeZone={viewingTimeZone}
            refetch={refetch}
          />
        ) : (
          <CalendarEvent
            key={eventGroupWithColor[0].key}
            eventInfo={{
              ...eventGroupWithColor[0],
              eventColor: EVENT_COLORS[0],
            }}
            localizedWeekdays={localizedWeekdays}
            focusedWeekday={focusedWeekday}
            locale={locale}
            mode24Hour={mode24Hour}
            viewingTimeZone={viewingTimeZone}
            refetch={refetch}
          />
        );
      })}
    </>
  );
};
