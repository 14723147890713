import { AssignmentRelationRole, User } from "@generated/graphql";
import clsx from "clsx";
import { Spinner } from "components/shared";
import { InputEditorProps } from "./ICalendarGeneratorPopover";
import { assignmentRelationRoleLabels } from "./iCalendarUtils";

/**
 * To work around limitations with the InputEditorProps type this is a function
 * that returns a functional component "Input Editor" that takes the props that
 * ICalendarGeneratorPopover expects.
 *
 * This is tailored for Teacher Tutors.
 */
export function ICalendarInputTT(userId: User["id"]) {
  const ICalendarInputTT = (props: InputEditorProps) => {
    return (
      <ICalendarInputEditorRelations
        userId={userId}
        inputEditorProps={props}
        relationChoices={[
          AssignmentRelationRole.CohortPrimaryTeacher,
          AssignmentRelationRole.CohortSubstituteTeacher,
          AssignmentRelationRole.EngagementSubstitute,
        ]}
      />
    );
  };

  return ICalendarInputTT;
}

/**
 * To work around limitations with the InputEditorProps type this is a function
 * that returns a functional component "Input Editor" that takes the props that
 * ICalendarGeneratorPopover expects.
 *
 * This is tailored for Mentor Teachers.
 */
export function ICalendarInputMT(userId: User["id"]) {
  const ICalendarInputMT = (props: InputEditorProps) => {
    return (
      <ICalendarInputEditorRelations
        userId={userId}
        inputEditorProps={props}
        relationChoices={[
          AssignmentRelationRole.CohortPrimaryTeacher,
          AssignmentRelationRole.CohortSubstituteTeacher,
          AssignmentRelationRole.EngagementMentor,
          AssignmentRelationRole.EngagementSubstitute,
        ]}
      />
    );
  };

  return ICalendarInputMT;
}

type ICalendarInputTeachersProps = {
  inputEditorProps: InputEditorProps;
  userId: User["id"];
  relationChoices: AssignmentRelationRole[];
};

/**
 * This "Input Editor" makes it possible to select which relations a user has to
 * the Cohorts (and Engagements) in the calendar.
 */
function ICalendarInputEditorRelations({
  inputEditorProps: { calendarNameFunc, debouncePending, input, onUpdateInput },
  userId,
  relationChoices,
}: ICalendarInputTeachersProps) {
  const relations =
    input.users?.find((u) => u.userId === userId)?.relations ?? [];

  const onUpdate = (newRelations: AssignmentRelationRole[]) => {
    if (input.users == null) {
      return;
    }

    const userIndex = input.users?.findIndex((u) => u.userId === userId);
    const newInput =
      userIndex !== undefined && userIndex !== -1
        ? {
            ...input,
            users: [
              ...input.users.slice(0, userIndex),
              {
                userId,
                relations: newRelations,
              },
              ...input.users.slice(userIndex + 1),
            ],
          }
        : {
            ...input,
            users: [
              ...(input.users ?? []),
              {
                userId,
                relations: newRelations,
              },
            ],
          };

    onUpdateInput({
      ...newInput,
      name: calendarNameFunc && calendarNameFunc(newInput),
    });
  };

  return (
    <fieldset className="relative">
      <div
        className="absolute bottom-0 left-0 flex items-center"
        aria-hidden="true"
      >
        {debouncePending && <Spinner color="text-blue-400" />}
      </div>
      <legend className="sr-only">Relations</legend>
      {relationChoices.map((relation) => (
        <CheckboxRow
          key={relation}
          id={relation}
          label={assignmentRelationRoleLabels[relation].label}
          description={assignmentRelationRoleLabels[relation].description}
          disabled={relations.includes(relation) && relations.length === 1}
          checked={relations.includes(relation)}
          onChange={(checked) => {
            if (checked) {
              onUpdate([...relations, relation]);
            } else {
              onUpdate(relations.filter((r) => r !== relation));
            }
          }}
        />
      ))}
    </fieldset>
  );
}

type CheckboxRowProps = {
  id: string;
  label: string;
  description: string;
  disabled: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

function CheckboxRow({
  id,
  label,
  description,
  disabled,
  checked,
  onChange,
}: CheckboxRowProps) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          id={id}
          name={id}
          type="checkbox"
          className={clsx(
            disabled ? "cursor-not-allowed" : "cursor-pointer",
            "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          )}
          disabled={disabled}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className="font-medium text-gray-700">
          {label}
        </label>
        <p className="text-gray-500">{description}</p>
      </div>
    </div>
  );
}
