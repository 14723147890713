import { gql, useMutation } from "@apollo/client";
import {
  CalendarEventCancelButtonMutation,
  CalendarEventCancelButtonMutationVariables,
  CohortEvent,
  ExDateStatus,
} from "@generated/graphql";
import { assertUnreachable } from "@utils/types";
import {
  triggerErrorToast,
  triggerSuccessToast,
  triggerWarningToast,
} from "components/shared";
import { TbCalendarX } from "react-icons/tb";

const CALENDAR_EVENT_CANCEL_MUTATION = gql`
  mutation CalendarEventCancelButton(
    $cohortEventId: ID!
    $isoStartFloatingDateTime: String!
  ) {
    addExDates(
      exDateInput: [
        {
          cohortEventId: $cohortEventId
          isoStartFloatingDateTime: $isoStartFloatingDateTime
        }
      ]
    ) {
      cohortEventId
      status
      startDateTime
      cohortId
      cohortName
    }
  }
`;

type Props = {
  cohortEventId: CohortEvent["id"];
  isoStartFloatingDateTime: string;

  refetch?: () => void;
};

export function CalendarEventCancelButton({
  isoStartFloatingDateTime,
  cohortEventId,
  refetch,
}: Props) {
  const [cancelEvent] = useMutation<
    CalendarEventCancelButtonMutation,
    CalendarEventCancelButtonMutationVariables
  >(CALENDAR_EVENT_CANCEL_MUTATION, {
    variables: {
      cohortEventId,
      isoStartFloatingDateTime,
    },
    onError: (error) => {
      refetch?.();
      triggerErrorToast({
        message: "Looks like something went wrong.",
        sub: "We weren't able to cancel this event.",
      });
      console.error(error);
    },
    onCompleted: ({ addExDates }) => {
      refetch?.();
      // This shouldn't happen, but just in case...
      if (addExDates.length === 0) {
        triggerErrorToast({
          message: "Could not cancel event.",
        });
        return;
      }
      const { status, cohortName, cohortEventId, startDateTime } =
        addExDates[0];
      switch (status) {
        case ExDateStatus.SuccessExcluded:
          triggerSuccessToast({
            message: `Event for ${cohortName} cancelled!`,
            sub: new Date(startDateTime).toLocaleString(),
          });
          break;
        case ExDateStatus.FailureEventInstanceInPast:
          triggerWarningToast({
            message: `Event for ${cohortName} could not be cancelled as it has already started!`,
            sub: new Date(startDateTime).toLocaleString(),
          });
          break;
        case ExDateStatus.FailureCohortSessionExists:
          triggerWarningToast({
            message: `Event for ${cohortName} could not be cancelled as it has already been recorded!`,
            sub: new Date(startDateTime).toLocaleString(),
          });
          break;
        case ExDateStatus.FailureCohortEventNotFound:
        case ExDateStatus.FailureEventInstanceNotFound:
          triggerErrorToast({
            message: "Could not cancel event.",
          });
          console.error(`cohortEventId: ${cohortEventId}, status: ${status}`);
          break;
        default:
          assertUnreachable(status);
      }
    },
  });

  return (
    <button
      className="text-xs text-red-500 font-medium underline-offset-2 hover:underline flex flex-row gap-x-0.5 tracking-tight"
      onClick={() => cancelEvent()}
    >
      <TbCalendarX className="w-4 h-4" />
      <span>Cancel</span>
    </button>
  );
}
