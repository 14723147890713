import { ISODate } from "@utils/dateTime";
import clsx from "clsx";
import { Icon } from "components/shared";
import { useAuth } from "contexts/AuthProvider";
import { addWeeks } from "date-fns";
import pluralize from "pluralize";
import { CalendarNavData } from "../types";
import { CalendarEventNavCancelButton } from "./CalendarEventNavCancelButton";
import { HolidaysIndicator } from "./Header/components/HolidaysIndicator";

type Props = {
  className: string;
  navData: CalendarNavData[];
  currentViewerISODate: ISODate;
  setViewDate: (date: Date) => void;
  viewDate: Date;
  refetch?: () => void;
  hideEventCount?: boolean;
};

export const DesktopNav = ({
  className,
  navData,
  viewDate,
  setViewDate,
  currentViewerISODate,
  refetch,
  hideEventCount = false,
}: Props) => {
  const { isAdmin } = useAuth();

  return (
    <div
      className={clsx(
        "-mr-px grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-4 text-gray-500 gap-y-1",
        className
      )}
    >
      <div className="col-end-1 w-14" />
      {navData.map(({ localizedWeekday, eventInfos }) => {
        const { isoDate, longWeekday, shortWeekday, day } = localizedWeekday;
        const currentDay = currentViewerISODate === isoDate;

        return (
          <div
            key={longWeekday}
            className="flex flex-col items-center justify-start pt-3 text-gray-900"
          >
            <button
              type="button"
              autoFocus={false}
              className="hidden sm:flex absolute flex-center z-20 h-full top-0 left-0 text-gray-500 w-10 rounded-md group-hover:text-blue-500 hover:bg-gray-100 px-1"
              onClick={() => setViewDate(addWeeks(viewDate, -1))}
            >
              <span className="sr-only">previous week</span>
              <Icon
                icon="chevronLeft"
                className="h-12 w-12 group-hover:scale-125"
              />
            </button>

            <div
              className={clsx(
                "flex flex-col px-2 py-1 w-full pt-[5px] flex-center",
                currentDay && "rounded-md bg-blue-500 text-white"
              )}
            >
              <span className="font-semibold capitalize whitespace-nowrap">
                {shortWeekday} {day}
              </span>
              <div className="flex flex-row flex-wrap gap-x-2 justify-center">
                {!hideEventCount && (
                  <div
                    className={clsx(
                      "text-gray-500 font-normal text-xs",
                      currentDay && "text-white"
                    )}
                  >
                    {pluralize("Event", eventInfos.length, true)}
                  </div>
                )}

                {isAdmin &&
                  currentViewerISODate <= isoDate &&
                  eventInfos.length !== 0 && (
                    <CalendarEventNavCancelButton
                      eventInfos={eventInfos}
                      localizedWeekday={localizedWeekday}
                      refetch={refetch}
                      currentDay={currentDay}
                    />
                  )}
              </div>
            </div>
            <button
              type="button"
              autoFocus={false}
              className="hidden sm:flex absolute flex-center z-20 h-full top-0 right-0 text-gray-500 w-10 rounded-md group-hover:text-blue-500 hover:bg-gray-100 px-1"
              onClick={() => setViewDate(addWeeks(viewDate, 1))}
            >
              <span className="sr-only">next week</span>
              <Icon
                icon="chevronRight"
                className="h-12 w-12 group-hover:scale-125"
              />
            </button>
          </div>
        );
      })}

      <div className="col-end-1 w-14 h-2" />
      {navData.map(({ localizedWeekday, navHolidays }) => (
        <div key={localizedWeekday.isoDate} className="w-full px-0.5 pb-1">
          <HolidaysIndicator navHolidays={navHolidays} />
        </div>
      ))}
    </div>
  );
};
