import { gql, useQuery } from "@apollo/client";
import {
  Cohort,
  NavigationCalendarsCohortsEventInstancesQuery,
  NavigationCalendarsCohortsEventInstancesQueryVariables,
  ScheduleNavigationCalendars_HolidayFragment,
} from "@generated/graphql";
import { onError } from "@utils/errorLogging";
import {
  NavigationCalendars,
  buildDayEventsCountMap,
  buildDayHolidaysMap,
  eventsAndHolidaysCellRenderer,
} from "components/shared/NavigationCalendars";
import { CalendarEventCohortInstanceInfo } from "components/weekCalendar";
import { useLayout } from "contexts/LayoutProvider";
import { addMonths, endOfMonth, startOfMonth, subMonths } from "date-fns";
import dayjs from "dayjs";
import { useMemo, useState } from "react";

ScheduleNavigationCalendars.fragments = {
  holiday: gql`
    fragment ScheduleNavigationCalendars_Holiday on Holiday {
      id
      name
      type
      endDate
      startDate
    }
  `,
};

const GET_ALL_EVENT_INSTANCES_QUERY = gql`
  query NavigationCalendarsCohortsEventInstances(
    $cohortIds: [ID!]!
    $dateTimeRange: DateTimeRangeInput!
    $includeExDates: Boolean!
  ) {
    cohortEventInstances(
      cohortIds: $cohortIds
      dateTimeRange: $dateTimeRange
      includeExDates: $includeExDates
    ) {
      startFloatingDateTime
    }
  }
`;

type Props = {
  viewDate: Date;
  endBound?: Date;
  startBound?: Date;
  cohortIds?: Cohort["id"][];
  events?: CalendarEventCohortInstanceInfo[];
  holidays?: ScheduleNavigationCalendars_HolidayFragment[];
  setViewDate: (date: Date) => void;
};

export function ScheduleNavigationCalendars({
  viewDate,
  endBound,
  startBound,
  events = [],
  holidays = [],
  cohortIds = [],
  setViewDate,
}: Props) {
  const { screenWidth } = useLayout();
  const showEvents = events.length > 0 || cohortIds.length > 0;
  const [calendarCount, setCalendarCount] = useState<number>(1);

  const dateTimeRange = useMemo(() => {
    const offset = Math.min(5, calendarCount);
    const end = +endOfMonth(addMonths(viewDate, offset));
    const start = +startOfMonth(subMonths(viewDate, offset));
    return { start, end };
  }, [calendarCount, viewDate]);

  const { data } = useQuery<
    NavigationCalendarsCohortsEventInstancesQuery,
    NavigationCalendarsCohortsEventInstancesQueryVariables
  >(GET_ALL_EVENT_INSTANCES_QUERY, {
    variables: {
      cohortIds,
      dateTimeRange,
      includeExDates: false,
    },
    onError,
    fetchPolicy: "cache-first",
  });

  const dayEventsCountMap = useMemo(() => {
    return buildDayEventsCountMap(
      data?.cohortEventInstances.map(
        ({ startFloatingDateTime }) => startFloatingDateTime
      ) ?? []
    );
  }, [data?.cohortEventInstances]);

  const dayHolidaysMap = useMemo(() => {
    return buildDayHolidaysMap(holidays);
  }, [holidays]);

  const cellRenderer = (current: dayjs.Dayjs, month: dayjs.Dayjs) =>
    eventsAndHolidaysCellRenderer(
      current,
      month,
      viewDate,
      dayEventsCountMap,
      dayHolidaysMap,
      showEvents,
      startBound,
      endBound
    );

  return (
    <NavigationCalendars
      viewDate={viewDate}
      calendarsWidth={screenWidth - 655}
      setViewDate={setViewDate}
      cellRenderer={cellRenderer}
      updateCalendarCount={setCalendarCount}
    />
  );
}
