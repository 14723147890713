import { CohortEventInstanceStatus } from "@generated/graphql";
import { IANAtzName, localizedTime, printDuration } from "@utils/dateTime";
import { getCohortSubjectText } from "@utils/labels";
import { Routes } from "@utils/routes";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { Link, Modal } from "components/shared";
import { CalendarEventEngagementAggregateInfo } from "components/weekCalendar";
import {
  getDateData,
  getEarliestEvent,
  getLatestEvent,
} from "components/weekCalendar/components/CalendarEvents/utils";
import { useAuth } from "contexts/AuthProvider";
import format from "date-fns/format";

type Props = {
  show: boolean;
  setShowModal: (show: boolean) => void;
  engagementAggregateEventInfos: CalendarEventEngagementAggregateInfo[];
  locale: string;
  mode24Hour: boolean;
  viewingTimeZone: IANAtzName;
};

export function CalendarEngagementAggregateEventsSummaryModal({
  show,
  setShowModal,
  engagementAggregateEventInfos,
  locale,
  mode24Hour,
  viewingTimeZone,
}: Props) {
  const { isAdmin } = useAuth();

  const earliestEvent = getEarliestEvent(engagementAggregateEventInfos);
  const latestEvent = getLatestEvent(engagementAggregateEventInfos);
  const startTime = format(earliestEvent.startDateTime, "EE, MMM d @ h:mm a");
  const endTime = format(latestEvent.endDateTime, "h:mm a");

  return (
    <Modal
      title={`${startTime} – ${endTime}`}
      icon={<Modal.Icon icon="calendarEvents" />}
      width="medium"
      onDismissClick={() => setShowModal(false)}
      show={show}
      onClose={() => setShowModal(false)}
    >
      <div
        className={clsx(
          "flex flex-col gap-y-1 max-h-[400px] overflow-auto",
          getScrollbarStyle("gray")
        )}
      >
        {engagementAggregateEventInfos.map((eventInfo, i) => {
          const startInfo = getDateData(eventInfo, viewingTimeZone);
          const endInfo = getDateData(eventInfo, viewingTimeZone, true);
          const cohortListBg =
            i % 2
              ? "odd:bg-white even:bg-slate-50"
              : "odd:bg-slate-50 even:bg-white";

          return (
            <div
              key={eventInfo.engagementId}
              className="flex flex-col gap-y-2 w-full odd:bg-white even:bg-slate-50 px-3 py-2 rounded"
            >
              <div className="flex flex-col gap-y-0">
                <Link
                  className={eventInfo.eventColor?.text}
                  disabled={!isAdmin}
                  route={Routes.engagement.details}
                  routeProps={[eventInfo.engagementId]}
                >
                  {eventInfo.title || "Untitled Event"}
                </Link>
                <p className="font-normal text-xs text-gray-500 tabular-nums">
                  {localizedTime(startInfo.time, mode24Hour, locale)} -{" "}
                  {localizedTime(endInfo.time, mode24Hour, locale)} (
                  {printDuration(eventInfo.durationMinutes, 60)})
                </p>
              </div>
              <div className="flex flex-col gap-y-0.5">
                {eventInfo.events.map((event) => (
                  <Link
                    key={event.cohortId}
                    className={clsx(
                      "flex flex-col gap-y-0 px-1.5 py-1 rounded",
                      cohortListBg
                    )}
                    disabled={!isAdmin}
                    route={Routes.cohort.details}
                    routeProps={[event.cohortId]}
                  >
                    <div className="flex flex-row gap-x-1 justify-between">
                      <div className="flex flex-col gap-y-0">
                        <p
                          className={clsx(
                            "text-sm text-wrap",
                            event.status === CohortEventInstanceStatus.Cancelled
                              ? "line-through text-slate-700"
                              : eventInfo.eventColor?.text ?? "text-slate-700"
                          )}
                        >
                          {event.cohortName}
                        </p>
                        <p className="font-light text-xs text-wrap text-slate-500">
                          {getCohortSubjectText(
                            event.cohortSubject,
                            "long",
                            event.cohortSubSubject
                          )}
                        </p>
                      </div>
                      {isAdmin && (
                        <p className="font-mono text-slate-500 text-xs text-nowrap tabular-nums">
                          ID: {event.cohortId}
                        </p>
                      )}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
}
