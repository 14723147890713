import { gql } from "@apollo/client";
import {
  BuildCohortSessionEventDetails_CohortFragment,
  Cohort,
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  CohortSession,
  Engagement,
  FilterCohortEventInstancesOnEngagementShifts_CohortEventInstanceFragment,
  FilterCohortEventInstancesOnEngagementShifts_EngagementShiftFragment,
  User,
  VideoProvider,
} from "@generated/graphql";
import {
  APP_DEFAULT_TIME_ZONE,
  MINUTE_MS,
  convertFromOneTimeZoneToAnother,
  formatDateTime,
  printDuration,
} from "@utils/dateTime";
import { makeCacheKey } from "@utils/strings";
import { format, utcToZonedTime } from "date-fns-tz";
import addMinutes from "date-fns/addMinutes";
import { groupBy } from "lodash";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import "react";
import { EventScheduleStatus } from "types/events";

export type CohortEventDetails<S> = {
  cohortSession?: S;
  cohortSubject: CohortAssignmentSubject;
  cohortSubSubject: CohortAssignmentSubSubject;
  status: EventScheduleStatus;
  durationMinutes: number;
  cohortId: Cohort["id"];
  cohortSessionId: CohortSession["id"] | null;
  activeStudentsCount: number;
  startDateTime: Date;
  endDateTime: Date;
};

buildCohortSessionEventDetails.fragments = {
  cohort: gql`
    fragment BuildCohortSessionEventDetails_Cohort on Cohort {
      id
      staffAssignments {
        cohortSubject
        user {
          id
        }
      }
      students {
        id
        createdAt
        removedAt
      }
      cohortSessions {
        id
      }
      eventInstances {
        startDateTime
        durationMinutes
        timeZone
        cohortId
        cohortSubject
        cohortSubSubject
        cohortSessionId
      }
    }
  `,
};

export function buildCohortSessionEventDetails<
  Session extends
    BuildCohortSessionEventDetails_CohortFragment["cohortSessions"][number],
>(
  cohort: BuildCohortSessionEventDetails_CohortFragment,
  currentDateTime: Date,
  targetUserId?: User["id"]
): CohortEventDetails<Session>[] {
  let targetedEventInstances = cohort.eventInstances;

  // If we have a targeted user we'll need to filter the relevant events.
  if (targetUserId != null) {
    // Gather the subjects that the targeted user is assigned to.
    const targetedSubjects = uniq(
      cohort.staffAssignments
        .filter((staffAssignment) => staffAssignment.user.id === targetUserId)
        .map((staffAssignment) => staffAssignment.cohortSubject)
    );

    // Filter the event instances that are relevant to the targeted user.
    targetedEventInstances = cohort.eventInstances.filter(
      (eventInstance) =>
        eventInstance.cohortId === cohort.id &&
        targetedSubjects.includes(eventInstance.cohortSubject)
    );
  }

  return sortBy(
    targetedEventInstances.map((eventInstance) => {
      const startDateTime = new Date(eventInstance.startDateTime);
      const endDateTime = addMinutes(
        startDateTime,
        eventInstance.durationMinutes
      );

      const status: EventScheduleStatus =
        endDateTime < currentDateTime
          ? EventScheduleStatus.CONCLUDED
          : startDateTime <= currentDateTime && endDateTime >= currentDateTime
          ? EventScheduleStatus.ONGOING
          : EventScheduleStatus.UPCOMING;

      const cohortSession = cohort.cohortSessions.find(
        (cs) => cs.id === eventInstance.cohortSessionId
      ) as Session | undefined; // TypeScript isn't smart enough to know this is S.

      const activeStudentsCount = cohort.students.filter(
        ({ createdAt, removedAt }) => {
          if (new Date(createdAt) > startDateTime) return false;
          return removedAt == null || new Date(removedAt) > startDateTime;
        }
      ).length;

      return {
        cohortSession,
        activeStudentsCount,
        cohortId: eventInstance.cohortId,
        cohortSessionId: eventInstance.cohortSessionId ?? null,
        cohortSubject: eventInstance.cohortSubject,
        cohortSubSubject:
          eventInstance.cohortSubSubject || CohortAssignmentSubSubject.General,
        startDateTime,
        endDateTime,
        durationMinutes: eventInstance.durationMinutes,
        status,
      };
    }),
    "startDateTime"
  );
}

export type DayAbbreviation = "SU" | "MO" | "TU" | "WE" | "TH" | "FR" | "SA";

export const mappingDayToNumber: Record<DayAbbreviation, number> = {
  SU: 0,
  MO: 1,
  TU: 2,
  WE: 3,
  TH: 4,
  FR: 5,
  SA: 6,
};

filterCohortEventInstancesOnEngagementShifts.fragments = {
  cohortEventInstance: gql`
    fragment FilterCohortEventInstancesOnEngagementShifts_CohortEventInstance on CohortEventInstance {
      startDateTime
      startFloatingDateTime
      timeZone
      durationMinutes
      cohortSubject
      cohortSubSubject
      cohortId
      cohortEventId
      teacherAssignedId
    }
  `,
  engagementShift: gql`
    fragment FilterCohortEventInstancesOnEngagementShifts_EngagementShift on EngagementShift {
      dayOfWeek
      startTime
      engagementId
      durationMinutes
      engagementShiftAssignments {
        userId
      }
    }
  `,
};

export function filterCohortEventInstancesOnEngagementShifts(
  eventInstances: FilterCohortEventInstancesOnEngagementShifts_CohortEventInstanceFragment[],
  engagementShifts: FilterCohortEventInstancesOnEngagementShifts_EngagementShiftFragment[],
  engagementId: Engagement["id"],
  targetUserIds: User["id"][]
): FilterCohortEventInstancesOnEngagementShifts_CohortEventInstanceFragment[] {
  const engagementShiftsUniqueGrouping = groupBy(
    engagementShifts,
    ({ dayOfWeek, startTime, durationMinutes, engagementId }) => {
      const day = mappingDayToNumber[dayOfWeek as DayAbbreviation];
      const estDate = utcToZonedTime(startTime, APP_DEFAULT_TIME_ZONE);
      const formattedEstDate = format(estDate, "HH:mm", {
        timeZone: APP_DEFAULT_TIME_ZONE,
      });

      return makeCacheKey(engagementId, day, formattedEstDate, durationMinutes);
    }
  );

  const entitiesGroup = groupBy(
    eventInstances,
    ({ startFloatingDateTime, timeZone, durationMinutes }) => {
      const estDate = convertFromOneTimeZoneToAnother(
        startFloatingDateTime,
        timeZone,
        APP_DEFAULT_TIME_ZONE
      );
      const time = formatDateTime(estDate);
      const day = estDate.getUTCDay();
      return makeCacheKey(engagementId, day, time, durationMinutes);
    }
  );

  const entitiesWithTeachersWithinShifts: FilterCohortEventInstancesOnEngagementShifts_CohortEventInstanceFragment[] =
    [];
  Object.keys(entitiesGroup).forEach((key) => {
    const engagementShiftsEntries = engagementShiftsUniqueGrouping[key];

    if (engagementShiftsEntries && targetUserIds) {
      const slotFound = engagementShiftsEntries.some(
        ({ engagementShiftAssignments }) =>
          engagementShiftAssignments.some(({ userId }) =>
            targetUserIds.includes(userId)
          )
      );

      if (slotFound) {
        entitiesWithTeachersWithinShifts.push(...entitiesGroup[key]);
      }
    }
  });

  return entitiesWithTeachersWithinShifts;
}

export const getTimeDescription = (
  currentDateTime: Date,
  startDateTime: Date,
  endDateTime: Date,
  status: EventScheduleStatus
): string => {
  if (status === EventScheduleStatus.CONCLUDED) {
    const minutesAgo = (+currentDateTime - +endDateTime) / MINUTE_MS;
    return ` ${printDuration(minutesAgo, 60)} ago`;
  } else if (status === EventScheduleStatus.ONGOING) {
    const elapsedMinutes = (+currentDateTime - +startDateTime) / MINUTE_MS;
    const remainingMinutes = (+endDateTime - +currentDateTime) / MINUTE_MS;

    // Only round up when over a minute remaining. Looks cleaner.
    return `, ${printDuration(elapsedMinutes, 60)} elapsed; ${printDuration(
      remainingMinutes >= 1 ? Math.ceil(remainingMinutes) : remainingMinutes,
      60
    )} remaining`;
  } else if (status === EventScheduleStatus.UPCOMING) {
    const minutesUntil = (+startDateTime - +currentDateTime) / MINUTE_MS;
    return ` in ${printDuration(minutesUntil, 60)}`;
  }
  return "";
};

export const liveAttendanceAvailable = (videoProvider?: VideoProvider) =>
  videoProvider === VideoProvider.Zoom ||
  videoProvider === VideoProvider.MicrosoftTeams;
