import { gql, useMutation } from "@apollo/client";
import {
  CalendarEventNavUndoButtonMutation,
  CalendarEventNavUndoButtonMutationVariables,
  UndoCancellationStatus,
} from "@generated/graphql";
import {
  Button,
  triggerErrorToast,
  triggerSuccessToast,
} from "components/shared";
import { CalendarEventCohortInstanceInfo } from "components/weekCalendar";
import pluralize from "pluralize";

const UNDO_CALENDAR_EVENT_NAV_CANCEL_MUTATION = gql`
  mutation CalendarEventNavUndoButton($undoCancellationInput: [ExDateInput!]!) {
    undoEventCancellation(undoCancellationInput: $undoCancellationInput) {
      cohortEventId
      status
      startDateTime
      cohortId
      cohortName
    }
  }
`;

type Props = {
  undoCancelledEvents: CalendarEventCohortInstanceInfo[];
  className?: string;
  buttonText?: string;
  onSuccess?: () => void;
  refetch?: () => void;
};

export function UndoCancelAllCalendarEventsButton({
  undoCancelledEvents,
  className,
  buttonText,
  onSuccess,
  refetch,
}: Props) {
  const [undoEventCancellations] = useMutation<
    CalendarEventNavUndoButtonMutation,
    CalendarEventNavUndoButtonMutationVariables
  >(UNDO_CALENDAR_EVENT_NAV_CANCEL_MUTATION, {
    variables: {
      undoCancellationInput: undoCancelledEvents
        .filter(({ cohortEventId }) => cohortEventId !== null)
        .map(({ cohortEventId, isoStartFloatingDateTime }) => ({
          cohortEventId: cohortEventId ?? "",
          isoStartFloatingDateTime,
        })),
    },
    onError: (error) => {
      refetch?.();
      triggerErrorToast({
        message: "Looks like something went wrong.",
        sub: `We weren't able to undo the ${pluralize(
          "cancellation",
          undoCancelledEvents.length,
          true
        )} of ${
          undoCancelledEvents.length > 1 ? "these events" : "this event"
        } .`,
      });
      console.error(error);
    },
    onCompleted: ({ undoEventCancellation }) => {
      refetch?.();
      onSuccess?.();

      const problematicResults = undoEventCancellation.filter(
        ({ status }) => status !== UndoCancellationStatus.Success
      );

      if (problematicResults.length === 0) {
        triggerSuccessToast({
          message: `${pluralize(
            "Event",
            undoEventCancellation.length,
            true
          )} successfully undone!`,
        });
      } else {
        triggerErrorToast({
          message: `Failed to undo ${pluralize(
            "event",
            problematicResults.length,
            true
          )} of ${pluralize("event", undoEventCancellation.length, true)}!`,
        });
      }
    },
  });

  return undoCancelledEvents.length !== 0 ? (
    <Button
      onClick={undoEventCancellations}
      className={className}
      theme="secondary"
      disabled={undoCancelledEvents.length === 0}
    >
      {buttonText ??
        `Undo ${pluralize(
          "Cancelled Event",
          undoCancelledEvents.length,
          true
        )}`}
    </Button>
  ) : null;
}
